import { Navbar, Nav } from 'react-bootstrap';
import '../styles/MyNav.css';
function MyNav() {
  return (
    <div  style={{width:"100%", backgroundColor:"#FFB830"}}>
      <Navbar bg="navBgColor" variant="dark" sticky="top" expand="sm" collapseOnSelect className="container" > 
          <Navbar.Brand>
          <a className="logo" href="#home">DocIt</a>
          
          </Navbar.Brand>

          <Navbar.Toggle/>
          <Navbar.Collapse className="justify-content-end" >
            <Nav className="">
              <Nav.Link className="" href="https://app.docit.dev/login"><span className="textColor">Login</span></Nav.Link>
              <Nav.Link className="" href="https://app.docit.dev/login"><span className="textColor">Sign Up</span></Nav.Link>
              {/* <Nav.Link className="" href="#problem"><span className="textColor">Problem</span></Nav.Link>
              <Nav.Link className="" href="#solution"><span className="textColor">Solution</span></Nav.Link> */}
              <Nav.Link className="" href="#signup"><span className="textColor">Mailing List</span></Nav.Link>
              <Nav.Link className="" href="#connect"><span className="textColor">Contact</span></Nav.Link>
              
            </Nav>
          </Navbar.Collapse>         
      </Navbar>
    </div>
  );
}

export default MyNav;
