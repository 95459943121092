import { Container, Row, Col, Form, Button, InputGroup,FormControl, Card, Image} from 'react-bootstrap';
import '../styles/Solution.css';

import Wiki from '../pictures/Wiki.jpg';
import scrRec from '../pictures/screenRecordingSolution.png';
import voiceRec from '../pictures/voiceRecordingSolution.png';
import diagram from '../pictures/diagramSolution.png';
import screenshot from '../pictures/screenshotSolution.png';
import workspace from '../pictures/workspaceSolution.png';

function Solution() {
  return (
    <div className="gradient">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 150"><path fill="#fff" fill-opacity="1" d="M0,64L48,80C96,96,192,128,288,128C384,128,480,96,576,101.3C672,107,768,149,864,138.7C960,128,1056,64,1152,37.3C1248,11,1344,21,1392,26.7L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
        <Container >
        <Card  style={{borderWidth: "0px"}} className="bg-transparent">
                        <Card.Body >
                            <h1 id="solution" className="solutionTitle mt-5 mb-5">
                                WITH DOCIT IT'S ALL IN ONE PLATFORM
                            </h1>

                            <h3 className="solutionSubtitle mt-5 mb-5">
                                Efficient and Easy to Use
                            </h3>
                        </Card.Body>
                    </Card>
            

            <Row className="align-items-center mb-5">
                <Col  className="">
                    <h1 className="solutionFeatureHeading">Screen Recording</h1>
                    <p className="solutionFeatureSubHeading">Screen Record your workflow with one click. Showing your work is much easier than writing about it. </p>
                </Col>
                
                <Col className="">
                    <Card  className="bg-transparent mb-3 cardDimentions">
                        <Card.Body >
                            <Image src={scrRec} className="card-img-top" alt="" fluid />      
                        </Card.Body>
                    </Card> 
                </Col>
            </Row>

            <Row className="align-items-center mb-5">
                <Col className="">
                    <Card  className="bg-transparent mb-3 cardDimentions">
                        <Card.Body >
                            <Image src={voiceRec} className="card-img-top" alt="" fluid />      
                        </Card.Body>
                    </Card> 
                </Col>
                <Col className="">
                    <h1 className="solutionFeatureHeading">Voice Recording</h1>
                    <p className="solutionFeatureSubHeading">Record your thoughts with one click. It is often easier to say it than to write it.  </p>
                </Col>
                
            </Row>
            <Row className="align-items-center mb-5">
                <Col className="">
                    <h1 className="solutionFeatureHeading">Diagram Maker</h1>
                    <p className="solutionFeatureSubHeading">Create all the diagram you want.    </p>
                </Col>
                
                <Col className="">
                    <Card  className="bg-transparent mb-3 cardDimentions">
                        <Card.Body >
                            <Image src={diagram} className="card-img-top" alt="" fluid />      
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="align-items-center mb-5">
                <Col className="">
                    <Card  className="bg-transparent mb-3 cardDimentions">
                        <Card.Body >
                            <Image src={screenshot} className="card-img-top" alt="" fluid />      
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="">
                    <h1 className="solutionFeatureHeading">Screenshot Tool</h1>
                    <p className="solutionFeatureSubHeading"> Make screenshots with one click. No more copying and pasting.</p>
                </Col>
                
            </Row>
            <Row className="align-items-center mb-5">
                <Col className="">
                    <h1 className="solutionFeatureHeading">Workspaces</h1>
                    <p className="solutionFeatureSubHeading">A workspace for your company, team, or project. Think Slack. </p>
                </Col>
                
                <Col className="">
                    <Card  className="bg-transparent mb-3 cardDimentions">
                        <Card.Body >
                            <Image src={workspace} className="card-img-top" alt="" fluid />      
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <h3 className="solutionSubtitle mt-5 mb-5">
                ...And much more! 
            </h3>
        </Container>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220"><path fill="#fff" fill-opacity="1" d="M0,32L60,32C120,32,240,32,360,48C480,64,600,96,720,112C840,128,960,128,1080,112C1200,96,1320,64,1380,48L1440,32L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>

    </div>
    
  );
}

export default Solution;