import './App.css';
import  MyNav  from './components/MyNav.js';
import PageHeader from './components/PageHeader';
import Description from './components/Description';
import Problem from './components/Problem';
import WithOut from './components/WithOut';
import Solution from './components/Solution';
import ConnectSignup from './components/ConnectSignup';
function App() {
  return (
    <div className="App">
      <MyNav/>
      <PageHeader/>
      <Description/>
      <Problem/>
      <WithOut/>
      <Solution/>
      <ConnectSignup/>
    </div>
  );
}

export default App;
