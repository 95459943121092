import { Container, Row, Col, Form, Button, InputGroup,FormControl, Card } from 'react-bootstrap';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import EmailSignUpForm from './EmailSignUpForm';
import '../styles/ConnectSignup.css';
function ConnectSignup() {
  return (
    <div id="connect" className="">
        <Container className="mb-5 pb-5">
            <Row className="align-items-center justify-content-center">
                <Col className="mb-5 pb-5">
                <MailchimpSubscribe
                        url={ process.env.REACT_APP_MAILCHIMP_URL }
                        render={ ( props ) => {
                        const { subscribe, status, message } = props || {};
                        return (
                            <EmailSignUpForm
                                status={ status }
                                message={ message }
                                onValidated={ formData => subscribe( formData ) }
                            />
                        );
                        }}
                    />
                </Col>
                <Col>
                    <Card  className="bg-transparent mb-3" fluid>
                        <Card.Body>  
                            <Card.Text className=" connectSignUpConnect bg-transparent">
                                
                                    CONNECT WITH US: docit.connect@gmail.com
                                
                            </Card.Text>
                        </Card.Body>
                    </Card> 
                </Col>
            </Row>
        </Container>
    </div>
    
  );
}

export default ConnectSignup;