import { Container, Row, Col, Form, Button, InputGroup,FormControl, Card, Image} from 'react-bootstrap';
import '../styles/WithOut.css';


import Wiki from '../pictures/Wiki.jpg';
import ScreenRec from '../pictures/ScreenRec.jpg';
import UML from '../pictures/UML.jpg';
import MSSuite from '../pictures/MSSuite.jpg';
import Snipping from '../pictures/Snipping-Tool.jpg';
import DocumentBuilders from '../pictures/DocumentBuilders.jpg';



function WithOut() {
  return (
    <div  className="gradient">
        <div className="withOutTopGradient">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fill-opacity="1" d="M0,192L1440,64L1440,0L0,0Z"></path></svg>
        </div>
        <Container className="">
        <Card  style={{borderWidth: "0px"}} className="bg-transparent">
                        <Card.Body >
                            <h2 id="today" className="title mb-3">
                                How it's Done without DocIt 
                            </h2>
                        </Card.Body>
                    </Card>
            
            <Row className="">
                <Col className="">
                    <Card  className="mb-3 cardProblem">
                        
                        <Card.Body>
                            <Card.Title className ="withOutCardTitle">Wiki's</Card.Title>
                            <Image src={Wiki} className="card-img-top" alt="Wiki" fluid />      
                            <Card.Text className="withOutCardText">
                                <p>
                                    Wiki's are messy and too wordy. Developers are not the best writers.
                                </p>
                            </Card.Text>
                        </Card.Body>
                    </Card> 
                </Col>
                <Col className="">
                    <Card  className="mb-3 cardProblem">
                        <Card.Body>
                            <Card.Title className ="withOutCardTitle">Screen Recording</Card.Title>
                            <Image src={ScreenRec} className="card-img-top" alt="ScreenRec" fluid/>      
                            <Card.Text className="withOutCardText">
                                <p>
                                    Current tools are for live video communication not documenting workflow. Converting video recordings can take hours! 
                                </p>
                            </Card.Text>
                        </Card.Body>
                    </Card> 
                </Col>
                    
                <Col className="">
                    <Card  className="mb-3 cardProblem">
                        <Card.Body>
                            <Card.Title className ="withOutCardTitle">Diagram Makers</Card.Title>
                            <Image src={UML} className="card-img-top" alt="UML diagram" fluid/>      
                            <Card.Text className="withOutCardText">
                                <p>
                                    Too many options and too much time to learn. 
                                </p>
                            </Card.Text>
                        </Card.Body>
                    </Card> 
                </Col>
            </Row>
            <Row className="">
                <Col className="">
                    <Card  className="mb-3 cardProblem">
                        
                        <Card.Body>
                            <Card.Title className ="withOutCardTitle">Microsoft Office Suite </Card.Title>
                            <Image src={MSSuite} className="card-img-top" alt="MSSuite" fluid />      
                            <Card.Text className="withOutCardText">
                                <p>
                                    Developers are documenting knowledge not cooking the books. Documentation is not a presentation. 
                                    A note is just not enough. Need say another Word?  
                                </p>
                            </Card.Text>
                        </Card.Body>
                    </Card> 
                </Col>
                <Col className="">
                    <Card  className="mb-3 cardProblem">
                        <Card.Body>
                            <Card.Title className ="withOutCardTitle">Screenshot Tools</Card.Title>
                            <Image src={Snipping} className="card-img-top" alt="Snipping" fluid/>      
                            <Card.Text className="withOutCardText">
                                <p>
                                    The snipping tool is the second greatest achievment after Copy and Paste. If only it wasn't so isolated.
                                </p>
                            </Card.Text>
                        </Card.Body>
                    </Card> 
                </Col>
                    
                <Col className="">
                    <Card  className="mb-3 cardProblem">
                        <Card.Body>
                            <Card.Title className ="withOutCardTitle">Document Builders</Card.Title>
                            <Image src={DocumentBuilders} className="card-img-top" alt="DocumentBuilders" fluid/>      
                            <Card.Text className="withOutCardText">
                                <p>
                                    Sleek but need to use a lot of integrations and third party tools.
                                </p>
                            </Card.Text>
                        </Card.Body>
                    </Card> 
                </Col>
            </Row>
        </Container>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 150"><path fill="#fff" fill-opacity="1" d="M0,64L48,80C96,96,192,128,288,128C384,128,480,96,576,101.3C672,107,768,149,864,138.7C960,128,1056,64,1152,37.3C1248,11,1344,21,1392,26.7L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
        </div>
    
  );
}

export default WithOut;