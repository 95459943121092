import { Container, Row, Col, Form, Button, InputGroup,FormControl, Card, Image} from 'react-bootstrap';
import '../styles/Problem.css';
import pic1 from '../pictures/boat.png';
import pic2 from '../pictures/boat2.jpg';

function Problem() {
  return (
    <div id="problem"  className="gradient">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 145"><path fill="#fff" fill-opacity="1" d="M0,64L120,64C240,64,480,64,720,74.7C960,85,1200,107,1320,117.3L1440,128L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path></svg>
        <Container className="">
        <Card  style={{borderWidth: "0px"}} className="bg-transparent">
                        <Card.Body >
                        <h2 className="problemTitle mb-3">
                Problem 
            </h2>
            <p className="problemDescription">
                A developer joins a company and has to spend <span className="problemEmphasis">MONTHS</span> getting upto speed because the codebase documentation sucks! When they do finaly get
                upto speed they make major contributions but <span className="problemEmphasis">RARELY</span> document their work beyond a crappy wiki and confusing code comments. Then they give
                you a two week notice leaving the team <span className="problemEmphasis">SCRAMBLING</span> trying to preserve as much information as humanly possible before there is a bug and the
                only person that knows how to fix it is already gone. <span className="problemEmphasis">CHAOS</span> 
            </p>
            <p className="whyProblemEmphasis">
            Time wasted. Developers burned-out. Money Lost.
            </p>
                        </Card.Body>
                    </Card>
            
        </Container>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 260"><path fill="#fff" fill-opacity="1" d="M0,256L120,245.3C240,235,480,213,720,176C960,139,1200,85,1320,58.7L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
    </div>
    
  );
}

export default Problem;