import { Container, Row, Col, Form, Button, InputGroup,FormControl } from 'react-bootstrap';
import { useState } from 'react';
import { decode } from 'html-entities';
import '../styles/EmailSignUpForm.css'; 

const EmailSignUpForm = ( { status, message, onValidated }) => {

  const [ error, setError ] = useState(null);
  const [ email, setEmail ] = useState(null);

  const handleFormSubmit = () => {

    setError(null);

    if ( ! email ) {
      setError( 'Please enter a valid email address' );
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    return email && email.indexOf("@") > -1 && isFormValidated;
  }

  
  const handleInputKeyEvent = ( event ) => {
    setError(null);
    if (event.keyCode === 13) {
      event.preventDefault();
      handleFormSubmit();
    }
  }

  const getMessage = (message) => {
    if ( !message ) {
     return null;
    }
    const result = message?.split('-') ?? null;
    if ( "0" !== result?.[0]?.trim() ) {
     return decode(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? decode( formattedMessage ) : null;
  }

 
    
      
  
  
  
  return (
  <Container>
    <InputGroup  id="signup" className="mt-5 pt-5">
      <FormControl size="lg"
            onChange={(event) => setEmail(event?.target?.value ?? '')}
            type="email"
            placeholder="Get early access"
            className="mr-2"
            onKeyUp={(event) => handleInputKeyEvent(event)}
            />
        <Button size="lg" className="bootButton" onClick={handleFormSubmit}>
          Sign Up
        </Button>
    </InputGroup>
        {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
        {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
        {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
  </Container>

  );
}

export default EmailSignUpForm