import { Container, Row, Col, Card } from 'react-bootstrap';
import { Grid, Button } from '@mui/material';
import Image from 'react-bootstrap/Image';
import boat from '../pictures/creativity.svg';
import '../styles/PageHeader.css';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import EmailSignUpForm from './EmailSignUpForm';
import rightPointingFinger from '../pictures/icons8-hand-right-96.png';
function PageHeader() {
    return (
        <div id="home" className="gradient">
            <Container className="containerStyle">
                <Row  className="align-items-center justify-content-center">
                    <Col className="pe-1">
                        <Card style={{ borderWidth: "0px"}} className="bg-transparent">
                            <Card.Body >
                                <h1 className="title">
                                    Document Your Code
                                </h1>
                                <p className="slogan">
                                    When your best people leave, make sure their ideas stay. DocIt
                                </p>
                            </Card.Body>
                        </Card>
                        <Card style={{ borderWidth: "0px", marginTop: "20px" }} className="bg-transparent">
                            <Card.Body >
                                <Grid container spacing={0}>
                                    <Grid  item xs={9}>
                                        <h2 className="alpha-launched">We Launched Our Alpha Version!</h2>
                                       
                                    </Grid>
                                    
                                    <Grid item xs={3}>
                                        {/* <Image src={rightPointingFinger} className="" alt="" fluid />  */}
                                        <Button href="https://app.docit.dev/login"  target="_blank" variant="contained" sx={ { borderRadius: 50, height: 145}}>
                                             <span className="blueButtonCirc">TRY IT NOW!</span>
                                        </Button>
                                    </Grid>
                                   
                                </Grid>
                                
                            </Card.Body>
                        </Card>

                        {/*
                    <div class="mt-5">
                        <input type="email" class="inputWidth" placeholder="Email me when it is ready" id="email"/>
                        <span class="input-group-btn">
                        <button class="buttonWidth">Send</button>
                        </span>
                    </div>*/}

                        <MailchimpSubscribe
                            url={process.env.REACT_APP_MAILCHIMP_URL}
                            render={(props) => {
                                const { subscribe, status, message } = props || {};
                                return (
                                    <EmailSignUpForm
                                        status={status}
                                        message={message}
                                        onValidated={formData => subscribe(formData)}
                                    />
                                );
                            }}
                        />


                    </Col>
                    <Col className="mt-5 ps-5">
                        <Image className="mt-5" style={{ minHeight: "200px", minWidth: "200px" }} src={boat} ></Image>
                    </Col>
                </Row>
            </Container>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#FFF" fill-opacity="1" d="M0,160L48,144C96,128,192,96,288,106.7C384,117,480,171,576,176C672,181,768,139,864,117.3C960,96,1056,96,1152,117.3C1248,139,1344,181,1392,202.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
        </div>

    );
}

export default PageHeader;
